// services/profile.service.ts

import { AxiosRequestConfig } from "axios";
import { callExternalApi } from "./external-api.service";
import { ProfileApiResponse } from "../models/profile-api-response";
import { ProfileResponse } from "../models/profile-response";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getProfile = async (
  accessToken: string
): Promise<ProfileApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/profile`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  // If there's an error, return it immediately
  if (error) {
    return {
      data: null,
      error,
    };
  }

  let parsedData: ProfileResponse | null = null;

  // Parse the data if it's a JSON string
  if (typeof data === "string") {
    try {
      parsedData = JSON.parse(data) as ProfileResponse;
    } catch (parseError) {
      return {
        data: null,
        error: {
          message: "Failed to parse response data",
        },
      };
    }
  } else {
    // If data is already an object, cast it to ProfileResponse
    parsedData = data as unknown as ProfileResponse;
  }

  return {
    data: parsedData,
    error: null,
  };
};
